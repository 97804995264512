.p-bar-custom-outer {
    height: 1.3rem;
    
}

.p-bar-custom-inner {
    background-color: rgb(33 150 243 / 62%) !important;
}

.time-remaining-text {
    position: absolute;
    padding-left: 12px;
    color: black;
}