main {
    padding-top:70px;
}
.alert-spacer-top {
    margin-top: 1rem;
}
input[type="file"] {
    display: none;
}
.custom-upload {
    box-shadow: 0 1px 4px rgb(0 0 0 / 40%);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.5;
    color: #444;
    background-color: transparent;
    border-width: 1px;
    border-color: transparent;
    text-align:center;
}
.edit-table-input {
    text-align: center!important;
    border: none;
    box-shadow:none!important;
    width:100%;
}