.leaflet-container {
  height: 400px;
  width: 100%;
  margin: 0 auto;
}
.leaflet-tooltip {
  background-color: transparent;
  color: #222;
  box-shadow: none;
  border: none;
  font-weight: bolder;
}
