.smallLabel {
    font-size:smaller;
}
.labelRow {
    margin-bottom: -10px;
}
.hyperlinkButton {
    background: none!important;
    border: none;
    padding: 0!important;
    color: var(--bs-link-color);
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    text-decoration: underline;
    cursor: pointer;
  }