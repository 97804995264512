.navbar-nav a, .navbar-nav a:link, .navbar-nav a.visited {
    color:white;
    text-decoration:none;
    margin-right:0.5em;
} 
.navbar-nav a:hover, .navbar-nav a:active {
    color:rgba(255,255,255,0.5);
}
.navbar-nav a.active {
    text-decoration:underline;
}