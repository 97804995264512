.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}
.marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
}
.featurette-divider {
    margin: 5rem 0;
}
.featurette-heading {
    line-height: 1;
    letter-spacing: -.05rem;
}
@media (min-width: 40em) {
    .featurette-heading {
        font-size: 32px;
    }
}
@media (min-width: 62em) {
    .featurette-heading {
        margin-top: 7rem;
    }
}