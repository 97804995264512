.arrowmenu-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 480px;
    padding: 0 40px;
  }
  
  .arrowmenu {
    font-family:"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Helvetica Neue", Arial, sans-serif;
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    margin: auto;
    text-align: center;
    top: 50%;
    width: 100%;
    height: 40px;
    z-index: 1;
    background-color: #d1d1d1;
    font-size: 14px;
  }
  
  .arrowmenu a {
    position: relative;
    display: flex;
    flex-grow: 1;
    text-decoration: none;
    margin: auto;
    height: 100%;
    padding-left: 38px;
    padding-right: 0;
    color: #212529;
  }
  
  .arrowmenu a:first-child {
    padding-left: 15.2px;
  }
  
  .arrowmenu a:last-child {
    padding-right: 15.2px;
  }
  
  .arrowmenu a:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 40px;
    height: 40px;
    top: 0;
    right: -20px;
    background-color: #d1d1d1;
    border-top-right-radius: 5px;
    -webkit-transform: scale(0.707) rotate(45deg);
            transform: scale(0.707) rotate(45deg);
    box-shadow: 2px -2px #fff;
    z-index: 1;
  }
  
  .arrowmenu a:last-child:after {
    content: none;
  }
  
  .arrowmenu-inner {
    display: flex;
    flex-direction: column;
    margin: auto;
    z-index: 2;
  }
  
  .arrowmenu-title {
    font-weight: normal;
  }
  
  .arrowmenu a.active,
  .arrowmenu a:hover {
    text-decoration:none;
  }
  
  .arrowmenu a.active,
  .arrowmenu a.active:after {
    color: #fff;
    background: #474747;  
  }

  .arrowmenu a:hover,
  .arrowmenu a:hover:after {
    color: #d0d0d0;
    background:#5E5E5E;
  }
  
  @media all and (max-width: 1000px) {
    .arrowmenu {
      font-size: 12px;
    }
  }
  @media all and (max-width: 710px) {
    .arrowmenu-desc {
      display: none;
    }
  
    .arrowmenu {
      height: 38px;
    }
  
    .arrowmenu a {
      padding-left: 25.3333333333px;
    }
  
    .arrowmenu a:after {
      content: "";
      width: 38px;
      height: 38px;
      right: -19px;
      -webkit-transform: scale(0.707) rotate(45deg);
              transform: scale(0.707) rotate(45deg);
    }
  }
  